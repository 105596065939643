import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import { replaceUnicode } from "../../src/components/anti/utils/utils"

import { useStaticQuery, graphql, replace } from "gatsby"
import { convertNwSeToNeSw } from "google-map-react"

function SEO({
  ampUrl,
  title,
  desc,
  url,
  fbImg,
  fbDesc,
  fbTitle,
  twitterImg,
  twitterDesc,
  twitterTitle,
  type = "website",
  schemaMarkup,
}) {
  const { site } = useStaticQuery(DEFAULT_SEO)

  const facebookPixelAllPagesDataLayer = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "fbPageview",
      page_url: window.location.href,
      page_title: title,
    })
    console.log("LOGGER_dataLayers === ", window.dataLayer)
  }

  const gtagFunction = (data) => {
    return window.dataLayer.push(data)
  }

  const gtagDataLayer = () => {
    window.dataLayer = window.dataLayer || []
    gtagFunction('js', new Date())
    gtagFunction('config', process.env.GTM_ID)
  }

  useEffect(() => {
    facebookPixelAllPagesDataLayer()
    gtagDataLayer()
  }, [])

  return (
    <Helmet title={replaceUnicode(title)} titleTemplate={`%s`} defer={false}>
      {/* <meta charSet="utf-8" /> */}
      <meta 
        name="google-site-verification" 
        content="JkJA8VRyUTpc6V6nOX3RDq-iBURiPri7vQCATvXKl9w" 
      />
      <meta
        name="title"
        content={`${replaceUnicode(title) || site.siteMetadata.title}`}
      />
      <meta name="description" content={desc} />
      <meta name="image" content={fbImg} />
      {!ampUrl ? (<link rel="canonical" href={url} />) : ("")}

      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta
        property="og:title"
        content={replaceUnicode(fbTitle) || fbTitle || site.siteMetadata.title}
      />
      <meta
        property="og:image"
        content={fbImg || site.siteMetadata.metaImage}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta
        property="og:image:alt"
        content={replaceUnicode(fbTitle) || fbTitle || site.siteMetadata.title}
      />
      <meta
        property="og:description"
        content={fbDesc || site.siteMetadata.description}
      />
      <meta property="fb:app_id" content="401011690922768" />

      <meta
        name="twitter:title"
        content={replaceUnicode(twitterTitle) || twitterTitle}
      />
      <meta
        name="twitter:image:src"
        content={twitterImg || site.siteMetadata.metaImage}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@infobsdcity" />
      <meta name="twitter:site:id" content="@infobsdcity" />
      <meta name="twitter:creator" content="@infobsdcity" />
      <meta
        name="twitter:description"
        content={twitterDesc || site.siteMetadata.description}
      />
      <meta name="twitter:url" content={url} />
      {schemaMarkup &&
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      }

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  ampUrl: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
  }),
  pathname: PropTypes.string,
  ampUrl: PropTypes.bool,
}

export default SEO

const DEFAULT_SEO = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        metaImage
      }
    }
  }
`
